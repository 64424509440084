import { __decorate } from "tslib";
import { Component, Vue, Prop, Ref, Watch, Emit, } from 'vue-property-decorator';
import { Table, Checkbox } from '@h3/antd-vue';
import { Loading } from '@cloudpivot-hermes/common-components';
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel = null;
let CTable = class CTable extends Vue {
    constructor() {
        super(...arguments);
        // 表格数据
        this.tableData = [];
        // 表格加载loading
        this.tableLoading = false;
        // 鼠标移上去显示table全选框
        this.isShowCheckedAllByMouse = false;
        // 列表竖向滚动的可视区域高度
        this.scrollHeight = 0;
        this.defaultPagination = {
            pageSize: 20,
            current: 1,
            total: 0,
        };
        this.defaultPaginationConfig = {
            pageSizeOptions: ['10', '20', '30', '40', '50', '60'],
            showQuickJumper: false,
            showSizeChanger: true,
            hideOnSinglePage: false,
            showLessItems: false,
            showTotal: (total) => `共${total}条`,
        };
        // 列表分页码配置
        this.pagination = {
            ...this.defaultPagination,
            ...this.defaultPaginationConfig,
            ...this.paginationConfig,
        };
    }
    clickTr(record) {
        return record;
    }
    change(tableData) {
        return tableData;
    }
    // 是否显示table的全选框
    get isShowCheckAllBox() {
        return this.isCheckedAll || this.indeterminate || this.isShowCheckedAllByMouse;
    }
    // 是否全选
    get isCheckedAll() {
        let result = false;
        if (Array.isArray(this.tableData) && this.tableData.length) {
            result = this.tableData.every((item) => item.isChecked);
        }
        return result;
    }
    get loading() {
        return this.enableLoading && this.tableLoading;
    }
    // 是否半全选
    get indeterminate() {
        let result = false;
        if (Array.isArray(this.tableData) && this.tableData.length) {
            const isCheckedAll = this.tableData.every((item) => item.isChecked);
            if (isCheckedAll) {
                result = false;
            }
            else {
                result = this.tableData.some((item) => item.isChecked);
            }
        }
        return result;
    }
    // 表格滚动的配置项
    get scrollConfig() {
        const config = { y: this.scrollHeight };
        if (this.isEnableScrollWidth) {
            config.x = this.scrollWdith;
        }
        return config;
    }
    get scrollWdith() {
        let result = 210;
        for (const c of this.tableColumns) {
            if (typeof c.width === 'number') {
                const width = c.width;
                result += width;
            }
        }
        return result;
    }
    async created() {
        await this.init();
        window.onresize = () => {
            this.setScrollHeight();
        };
    }
    async init() {
        if (this.immediate) {
            await this.getTableList();
        }
    }
    /**
     * 动态设置静态可滚动的高度
     */
    async setScrollHeight() {
        if (typeof this.setTableHeight === 'function') {
            await this.setTableHeight();
        }
        const cTable = this.cTable;
        let scrollHeight = 430;
        if (!cTable)
            return;
        // 除掉表头高度
        scrollHeight = cTable.clientHeight - 42;
        // 除掉分页码高度
        if (this.pagination) {
            scrollHeight = scrollHeight - 32 - 12;
        }
        this.scrollHeight = scrollHeight;
    }
    /**
     * 去掉小表格的小分页码样式类
     */
    async removeTableSmallStyle() {
        await this.$nextTick();
        let paginationCom = this.aTable;
        let index = 0;
        const indArr = [0, 0, 1];
        while (paginationCom && index < indArr.length) {
            paginationCom = await this.getComponent(paginationCom, indArr[index]);
            index++;
        }
        if (!paginationCom)
            return;
        const pagination = paginationCom.$el;
        if (!pagination)
            return;
        pagination.className = pagination.className.replace(' mini', '');
    }
    /**
     * 获取子组件
     */
    getComponent(aTable, index) {
        return new Promise((resolve) => {
            const id = setInterval(() => {
                const comp = aTable.$children[index];
                if (!comp)
                    return;
                clearInterval(id);
                resolve(comp);
            });
        });
    }
    /**
     * 获取列表数据
     */
    getTableList(params) {
        if (typeof cancel === 'function') {
            cancel();
        }
        this.tableLoading = true;
        let serveParams = {
            ...params,
        };
        if (this.pagination) {
            serveParams = {
                ...serveParams,
                page: this.pagination.current,
                size: this.pagination.pageSize,
            };
        }
        return this.getList(serveParams, {
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then((res) => {
            if (!res.success)
                return;
            const tableData = Array.isArray(res.data) ? res.data : [];
            this.tableData = tableData.map((item, index) => {
                return {
                    index: this.enableGlobalSerialNumber && this.pagination
                        ? this.pagination.pageSize * (this.pagination.current - 1) + index + 1 : index + 1,
                    isMouseOver: false,
                    isChecked: false,
                    disabled: false,
                    ...item,
                };
            });
            this.pagination.total = res.total || 0;
            return res.success;
        }).finally(() => {
            this.setScrollHeight();
            this.removeTableSmallStyle();
            this.tableLoading = false;
            cancel = null;
        });
    }
    // 获取选中的行
    getSelectionRow() {
        return this.tableData.filter(item => item.isChecked);
    }
    /**
     * 全选或不全选
     */
    onChangeCheckAll(e) {
        const checked = e.target.checked;
        for (const item of this.tableData) {
            if (!item.disabled) {
                item.isChecked = checked;
            }
        }
    }
    /**
     * 操作表格头
     */
    onHeaderRowClick() {
        return {
            on: {
                mouseenter: () => {
                    this.isShowCheckedAllByMouse = true;
                },
                mouseleave: () => {
                    this.isShowCheckedAllByMouse = false;
                },
            },
        };
    }
    /**
     * 表格点击事件
     */
    onTableRowClick(record) {
        let isdbClick = false;
        let timer = 0;
        let isMouseDown = false;
        let startPointer = 0;
        let endPointer = 0;
        return {
            on: {
                mouseenter: () => {
                    record.isMouseOver = true;
                },
                mouseleave: () => {
                    record.isMouseOver = false;
                },
                mousedown: (e) => {
                    isMouseDown = true;
                    startPointer = e.pageX;
                },
                mouseup: (e) => {
                    if (isMouseDown) {
                        isMouseDown = false;
                        endPointer = e.pageX;
                    }
                },
                dblclick: () => {
                    isdbClick = true;
                },
                click: () => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        // 双击和选中文本都不执行单击程序
                        if (isdbClick || Math.abs(endPointer - startPointer) !== 0)
                            return;
                        isdbClick = false;
                        this.clickTr(record);
                    }, 200);
                },
            },
        };
    }
    /**
     * 字段排序
     */
    onRank({ order, field }) {
        order === 'ascend'
            ? this.tableData.sort((a, b) => a[field].localeCompare(b[field]))
            : this.tableData.sort((a, b) => b[field].localeCompare(a[field]));
        this.tableData = this.tableData.map((item, index) => {
            return { ...item, index: index + 1 };
        });
    }
    /**
     * 监听表格变化（单击分页、单击表头列排序）
     */
    tableChange(page, filters, sorter) {
        if (this.pagination.current !== page.current || this.pagination.pageSize !== page.pageSize) {
            this.pagination.current = page.current;
            this.pagination.pageSize = page.pageSize;
            this.getTableList();
            return;
        }
        ;
        if (sorter) {
            this.onRank(sorter);
        }
    }
    getTableData() {
        return this.tableData;
    }
};
__decorate([
    Ref()
], CTable.prototype, "aTable", void 0);
__decorate([
    Ref()
], CTable.prototype, "cTable", void 0);
__decorate([
    Prop({
        default: () => {
            return [];
        },
    })
], CTable.prototype, "tableColumns", void 0);
__decorate([
    Prop({
        default: () => {
            return {};
        },
    })
], CTable.prototype, "paginationConfig", void 0);
__decorate([
    Prop({ default: true })
], CTable.prototype, "canScroll", void 0);
__decorate([
    Prop({ default: false })
], CTable.prototype, "isEnableScrollWidth", void 0);
__decorate([
    Prop({ default: false })
], CTable.prototype, "enableGlobalSerialNumber", void 0);
__decorate([
    Prop({ default: () => { } })
], CTable.prototype, "rowClassName", void 0);
__decorate([
    Prop({ default: true })
], CTable.prototype, "immediate", void 0);
__decorate([
    Prop({ default: true })
], CTable.prototype, "enableLoading", void 0);
__decorate([
    Prop({ default: () => { } })
], CTable.prototype, "getList", void 0);
__decorate([
    Prop({ default: null })
], CTable.prototype, "setTableHeight", void 0);
__decorate([
    Prop({ default: false })
], CTable.prototype, "enableCheckbox", void 0);
__decorate([
    Emit('clickTr')
], CTable.prototype, "clickTr", null);
__decorate([
    Watch('tableData', { immediate: true, deep: true }),
    Emit('change')
], CTable.prototype, "change", null);
CTable = __decorate([
    Component({
        name: 'CTable',
        components: {
            ATable: Table,
            ACheckbox: Checkbox,
            Loading,
        },
    })
], CTable);
export default CTable;
